import React from 'react';
import CenterContent from "./elements/CenterContent";
import {Translate} from "react-localize-redux";

const Done = () => {
    return (
        <CenterContent>
            <h1>
                <Translate id="done.text"/>
            </h1>
        </CenterContent>
    )
}

export default Done;