import React from 'react';
import gfx_logo from '../gfx/logo_connect.svg';

const Header = ({meetingTitle}) => {
  return (
    <div className="Header">
      <img src={gfx_logo} alt="montega connect" className="brand"/>
      <h2 className="meetingTitle" dangerouslySetInnerHTML={{__html: meetingTitle}}/>
    </div>
  )
}

export default Header;