import React from 'react';
import CenterContent from "./CenterContent";
import {Translate} from "react-localize-redux";

const NoParams = () => {
    return (
        <CenterContent>
            <h1>
                <Translate id="noParams.text"/>
            </h1>
        </CenterContent>
    )
}

export default NoParams;