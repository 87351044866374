import React from 'react';
import BorderWithDot from "./BorderWithDot";
import {Translate} from "react-localize-redux";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-center justify-content-lg-end">
            <BorderWithDot borderClass="border-with-dot-left border-with-dot-white d-none d-md-flex"/>
            <a href={process.env.REACT_APP_FOOTER_LINK_PRIVACY} target="_blank" rel="noopener norefferer">
              <Translate id="footer.privacy"/>
            </a>
            <div className="border-middle"/>
            <a href={process.env.REACT_APP_FOOTER_LINK_IMPRINT} target="_blank" rel="noopener norefferer">
              <Translate id="footer.imprint"/>
            </a>
            <BorderWithDot borderClass="border-with-dot-white d-none d-md-flex"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;