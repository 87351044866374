import React from 'react';

const CenterContent = ({children}) => {
  return (
    <div className="CenterContent">
      {children}
    </div>
  )
};

export default CenterContent;