import React, {Fragment, useEffect, useState} from 'react';
import ScriptTag from 'react-script-tag';
import {useParams} from "react-router";
import axios from "axios";
import CenterContent from "./elements/CenterContent";
import $ from 'jquery';
import {Translate, withLocalize} from "react-localize-redux";

const Meeting = ({activeLanguage, setActiveLanguage}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [meetingConfig, setMeetingConfig] = useState({});
  const [showError, setShowError] = useState(false);
  const [loadedDependencies, setLoadedDependencies] = useState([]);

  let {eventItemId, userItemId} = useParams();

  let searchWaitingButtonInterval, waitForEntryInterval;

  useEffect(() => {
    if (eventItemId && userItemId) {
      setShowError(false);
      axios.post(`${process.env.REACT_APP_API_URL}/zoom/signature`, {
        event: eventItemId,
        user: userItemId
      })
        .then(result => {
          if (result.status === 200) {
            setMeetingConfig({
              title: result.data.data.title,
              userName: result.data.data.name,
              userEmail: result.data.data.userEmail,
              signature: result.data.data.signature,
              meetingNumber: result.data.data.meetingNumber,
              passWord: result.data.data.pwd,
              tk: result.data.data.tk
            });
            setActiveLanguage(result.data.data.lang);
            setDataLoaded(true);
          } else {
            setShowError(true);
          }
        })
        .catch(error => {
          setShowError(true);
        });
    }
  }, [eventItemId, userItemId]);

  const checkLoadedDependencies = (dependency = null) => {
    let currentLoadedDependencies;
    if (dependency) setLoadedDependencies(v => {
      currentLoadedDependencies = [...v, dependency];
      return currentLoadedDependencies;
    });

    const neededDependencies = ['react', 'react-dom', 'redux', 'redux-thunk', 'lodash', 'zoom-meeting'];

    if (neededDependencies.every(dep => currentLoadedDependencies.includes(dep))) {
      init();
    }
  }

  const init = () => {
    let ZoomMtg = window.ZoomMtg;
    if (ZoomMtg) {
      ZoomMtg.setZoomJSLib('https://source.zoom.us/2.9.5/lib', '/av');
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();

      // loads language files, also passes any error messages to the ui
      // zoom doc: load zoom support language  ['de-DE', 'es-ES', 'en-US', 'fr-FR', 'jp-JP', 'pt-PT','ru-RU', 'zh-CN', 'zh-TW', 'ko-KO', 'it-IT', 'vi-VN']
      //const languages = ['de-DE', 'es-ES', 'en-US', 'fr-FR', 'jp-JP', 'pt-PT','ru-RU', 'zh-CN', 'zh-TW', 'ko-KO', 'it-IT', 'vi-VN'];
      //let lang = languages.find( element => element.includes(navigator.language));
      let lang = activeLanguage?.code === 'de' ? 'de-DE' : 'en-US';
      ZoomMtg.i18n.load(lang);
      ZoomMtg.i18n.reload(lang);

      ZoomMtg.init({
        leaveUrl: '/done',
        success: (success) => {
            $('#content_container').prepend('<div class="unauthorized-bg"/>');
            searchWaitingButtonInterval = setInterval(searchWaitingButton, 2000);
          ZoomMtg.join({
            signature: meetingConfig.signature,
            sdkKey: process.env.REACT_APP_ZOOM_SDK_KEY,
            meetingNumber: meetingConfig.meetingNumber,
            userName: meetingConfig.userName,
            userEmail: meetingConfig.userEmail,
            passWord: meetingConfig.passWord,
            tk: meetingConfig.tk,
            disableInvite: true,
            success: (success) => {
            },
            error: (error) => {
            }
          })
        },
        error: (error) => {
        }
      })
    }
  }

  const searchWaitingButton = () => {
    let waitingButtonContainer = $('body .waiting-pannel-btn, body .new-register-webinar');
    if (waitingButtonContainer.length > 0) {
      let waitingButton = $(waitingButtonContainer).find('button')?.first();
      if (waitingButton) {
        if (searchWaitingButtonInterval) clearInterval(searchWaitingButtonInterval);
        waitForEntryInterval = setInterval(checkIfEntryPossible, 1000);
      }
    }
  };

  const checkIfEntryPossible = () => {
    let waitingButton = $('body .waiting-pannel-btn button, body .new-register-webinar button')?.first();
    if (!$(waitingButton).hasClass('zm-btn--disabled') && !$(waitingButton).hasClass('leaveWaitingBtn')) {
      $(waitingButton).click();
      if (waitForEntryInterval) clearInterval(waitForEntryInterval);
    }
  }

  return showError ? (
    <CenterContent>
      <h1>
        <Translate id="meeting.error"/>
      </h1>
    </CenterContent>
  ) : (dataLoaded ? (
      <Fragment>
        <ScriptTag src="https://source.zoom.us/2.9.5/lib/vendor/react.min.js"
                   onLoad={() => checkLoadedDependencies('react')}/>
        {loadedDependencies?.includes('react') && (
          <Fragment>
            <ScriptTag src="https://source.zoom.us/2.9.5/lib/vendor/react-dom.min.js"
                       onLoad={() => checkLoadedDependencies('react-dom')}/>
            <ScriptTag src="https://source.zoom.us/2.9.5/lib/vendor/redux.min.js"
                       onLoad={() => checkLoadedDependencies('redux')}/>
            <ScriptTag src="https://source.zoom.us/2.9.5/lib/vendor/redux-thunk.min.js"
                       onLoad={() => checkLoadedDependencies('redux-thunk')}/>
            <ScriptTag src="https://source.zoom.us/2.9.5/lib/vendor/lodash.min.js"
                       onLoad={() => checkLoadedDependencies('lodash')}/>
            {['react-dom', 'redux', 'redux-thunk', 'lodash'].every(dep => loadedDependencies?.includes(dep)) && (
              <ScriptTag src="https://source.zoom.us/zoom-meeting-2.9.0.min.js"
                         onLoad={() => checkLoadedDependencies('zoom-meeting')}/>
            )}
          </Fragment>
        )}
      </Fragment>
    ) : (
      <CenterContent>
        <h1>
          <Translate id="meeting.loading"/>
        </h1>
      </CenterContent>
    )
  )
}

export default withLocalize(Meeting);
