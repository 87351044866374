import React, {useEffect, useState} from 'react';
import Header from "./elements/Header";
import Footer from "./elements/Footer";
import {useParams} from "react-router";
import NoParams from "./elements/NoParams";
import axios from "axios";
import {withLocalize} from "react-localize-redux";

const Main = ({setActiveLanguage}) => {

    let {eventItemId, userItemId} = useParams();
    const [meetingTitle, setMeetingTitle] = useState('');

    useEffect(() => {
        if (eventItemId) {
            axios.post(`${process.env.REACT_APP_API_URL}/zoom/title`, {
                event: eventItemId,
                user: userItemId
            })
                .then(result => {
                    if (result.status === 200) {
                        setMeetingTitle(result.data.data?.title);
                        setActiveLanguage(result.data.data?.lang);
                    }
                })
        }
    }, [eventItemId]);

    return (
        <div className="Main">
            <Header meetingTitle={meetingTitle}/>
            <div className="iframe-container">
                {eventItemId && userItemId ? (
                    <iframe src={`/meeting/${eventItemId}/${userItemId}`} allowFullScreen={true} allowusermedia="true"
                            title="Meeting"/>
                ) : (
                    <NoParams/>
                )}
            </div>
            <Footer/>
        </div>
    )
}

export default withLocalize(Main);
