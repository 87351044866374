import React from 'react';
import './theme/theme.scss';
import {Route, Routes} from "react-router";
import Main from "./Main";
import Done from "./Done";
import Meeting from "./Meeting";
import {renderToStaticMarkup} from "react-dom/server";
import globalTranslations from "./translations/global";
import {withLocalize} from "react-localize-redux";
import {connect} from "react-redux";

const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;

class App extends React.Component {

    constructor(props) {
        super(props);

        let languageFromStore = this.props.localize?.languages?.find(l => l.active)?.code;
        let userLang = navigator.language || navigator.userLanguage;
        let defaultLanguage = languageFromStore ? languageFromStore : (userLang === 'de-DE' ? 'de' : 'en');

        this.props.initialize({
            languages: [
                {name: "German", code: "de"},
                {name: "English", code: "en"}
            ],
            translations: globalTranslations,
            options: {renderToStaticMarkup, onMissingTranslation, defaultLanguage: defaultLanguage},
        });
        this.props.addTranslation(globalTranslations);
    }

    render() {
        return (
            <div className="App">
                <Routes>
                    <Route path="/meeting/:eventItemId/:userItemId" element={<Meeting/>}/>
                    <Route path="/done" element={<Done/>}/>
                    <Route path="/:eventItemId/:userItemId" element={<Main/>}/>
                    <Route path="/:eventItemId" element={<Main/>}/>
                    <Route path="/" element={<Main/>}/>
                </Routes>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    localize: state.localize
});

export default withLocalize(connect(mapStateToProps, null)(App));
